import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Logo/Logo';
import {Web3Connect} from '../Web3Connect/Web3Connect';
import { BreakpointUpContainer } from '../../styles/Breakpoints';
import theme from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";
import ChainHandler from '../ChainHandler/ChainHandler';

const Navbar = ({globalState, setGlobalState}) => {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <>
      <Header>
        <Nav>
          <div>
            <Logo to="/" altText="polybet" />
          </div>
          {/* <div>
            <LinkUnderlined to="/DAO">{t('header.polybet_dao')}</LinkUnderlined>
          </div> */}
          <NavList>
            <li>
              <LanguageSwitch>
                <span className="switch-text active" onClick={() => changeLanguage('en')}>En</span>
                <span className="switch-bdr">|</span>
                <span className="switch-text" onClick={() => changeLanguage('zh')}>中文</span>
              </LanguageSwitch>
            </li>
            <li>
              <Web3Connect globalState={globalState} setGlobalState={setGlobalState}></Web3Connect>
            </li>
          </NavList>
        </Nav>
      </Header>

      <ChainHandler globalState={globalState} setGlobalState={setGlobalState}></ChainHandler>
    </>
  )
}

export default Navbar

// styles 
const Header = styled.header.attrs({ className: 'navbar'})`
  position: relative;
  width: 100%;
  background-color: rgba(255,255,255,.8);
  box-shadow: 0 0 6px rgba(11, 11, 11, 0.15);
`;

const Nav = styled.nav.attrs({ className: 'navbar__container'})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  min-height: 60px;

  ${BreakpointUpContainer} {
    max-width: 1028px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 991px) {
    gap: 12px;
  }
`;

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;

  >li {
    &:not(:first-child) {
      padding-left: 15px;
    }
  }
`;

const NavLink = styled(Link)`
  display: block;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
`;

const LinkUnderlined = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  color: ${theme.lightBlue};
  display: inline-block;
  padding: 6px 12px;
  vertical-align: middle; 

  &:is(:hover, :focus-visible) {
    color: ${theme.darkBlue};
  }
`;

const LanguageSwitch = styled.span`
  font-size: 14px;

  span {
    display: inline-block;
  }

  .switch-bdr,
  .switch-text {
    color: #7b7b7b;
  }

  .switch-bdr {
    padding: 0 5px;
  }

  .switch-text {
    cursor: pointer;

    &.active {
      color: ${theme.lightTeal};
    }
  }
`;