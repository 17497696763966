import React, { useState, useEffect, useRef } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import rocketImage from '../../assets/rocket@2x.png';

const Rocket = () => {
  const [screenHeight, setScreenHeight] = useState(null);
  let elm = useRef(null);
 
  useScrollPosition(({ prevPos, currPos }) => {
    setScreenHeight((window.innerHeight - 200) + currPos.y / 2);
  })

  useEffect(() => {
    setScreenHeight(window.innerHeight - 220); 
  }, []);

  return (
    <div ref={elm} className="rocket" style={{ top: screenHeight}}><img src={rocketImage} alt="Rocket"/></div>
  )
}

export default Rocket;